// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10881(b255dd660d)-C13/11/2024-18:19:05-B13/11/2024-18:19:50' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10881(b255dd660d)-C13/11/2024-18:19:05-B13/11/2024-18:19:50",
  branch: "master",
  latestTag: "6.1",
  revCount: "10881",
  shortHash: "b255dd660d",
  longHash: "b255dd660dd8142d5317bafbac569dd1dac7a83f",
  dateCommit: "13/11/2024-18:19:05",
  dateBuild: "13/11/2024-18:19:50",
  buildType: "Ansible",
  } ;
